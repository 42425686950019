import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Tag from "../components/tag"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { styled } from "@material-ui/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

const styles = {
  "& .fab": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    position: "fixed",
    zIndex: 2,
    bottom: 24,
    right: 24,
    borderRadius: "50%",
    background: "white",
    boxShadow: "0 0 8px #DDD",
  },
  ".dark & .fab": {
    background: "var(--bg)",
    boxShadow: "0 0 8px #666",
  },
}

const scrollToBottom = () => {
  window.scrollTo(0, document.scrollingElement.scrollHeight)
}

const getAvatarFixed = gatsbyImgObj =>
  (gatsbyImgObj &&
    gatsbyImgObj.childImageSharp &&
    gatsbyImgObj.childImageSharp.fixed) ||
  null

const BlogPostTemplate = ({
  data,
  pageContext,
  location,
  className,
  ...otherProps
}) => {
  const post = data.mdx
  // console.log('blogPostTemplate: data: ', data)
  // console.log('blogPostTemplate: post: ', post)
  const editor_avatar = getAvatarFixed(data.editor_avatar)
  const writer_avatar = getAvatarFixed(data.writer_avatar)
  const recorder_avatar = getAvatarFixed(data.recorder_avatar)
  const {
    frontmatter: {
      editor = null,
      editor_summary = null,
      recorder = null,
      recorder_summary = null,
      writer,
      writer_summary,
    } = {},
  } = post
  // console.log('blogPostTemplate: authors info: ',
  //   recorder, recorder_avatar, recorder_summary,
  //   writer, writer_avatar, writer_summary
  // )
  // const siteTitle = data.site.siteMetadata.title
  const { previous: next, next: previous } = pageContext
  return (
    <Layout
      location={location}
      title={
        <div>
          <div style={{ fontSize: "50%" }}>back to</div>Home
        </div>
      }
      {...{ className }}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <button className="fab" onClick={scrollToBottom}>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <article>
        <header>
          <h1
            style={{
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
          {post.frontmatter.categories.length && (
            <p>
              {post.frontmatter.categories.map(c => (
                <Tag tag={c} key={c}></Tag>
              ))}
            </p>
          )}
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio
            {...{
              editor,
              editor_summary,
              editor_avatar,
              recorder,
              recorder_summary,
              recorder_avatar,
              writer,
              writer_avatar,
              writer_summary,
            }}
          />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default styled(BlogPostTemplate)(styles)

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $writerAvatarRegex: String!
    $recorderAvatarRegex: String!
    $editorAvatarRegex: String!
  ) {
    writer_avatar: file(absolutePath: { regex: $writerAvatarRegex }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    recorder_avatar: file(absolutePath: { regex: $recorderAvatarRegex }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    editor_avatar: file(absolutePath: { regex: $editorAvatarRegex }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: { regex: $slug }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        categories
        date(formatString: "MMMM DD, YYYY")
        description
        writer
        writer_avatar
        writer_summary
        recorder
        recorder_avatar
        recorder_summary
        editor
        editor_summary
        editor_avatar
      }
    }
  }
`
