/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const AuthorBlock = ({ name, avatar, summary, action }) => (
  <div
    style={{
      display: `flex`,
      alignItems: "center",
      // marginBottom: rhythm(2.5),
    }}
  >
    <Image
      fixed={avatar}
      alt={name}
      style={{
        marginRight: rhythm(1 / 2),
        marginBottom: 0,
        minWidth: 50,
        borderRadius: `100%`,
      }}
      imgStyle={{
        borderRadius: `50%`,
      }}
    />
    <div>
      {action} by <strong>{name}</strong>
      {summary ? `, ${summary}` : ""}
    </div>
  </div>
)

const Bio = ({
  editor = null,
  editor_summary = null,
  editor_avatar = null,
  recorder = null,
  recorder_summary = null,
  recorder_avatar = null,
  writer,
  writer_summary,
  writer_avatar,
}) => {
  return (
    <div
      style={{
        // display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <div>
        <AuthorBlock
          name={writer}
          summary={writer_summary}
          avatar={writer_avatar}
          action="Written"
        />
        {recorder && (
          <>
            <div style={{ marginBottom: rhythm(0.5) }} />
            <AuthorBlock
              name={recorder}
              summary={recorder_summary}
              avatar={recorder_avatar}
              action="Recorded"
            />
          </>
        )}
        {editor && (
          <>
            <div style={{ marginBottom: rhythm(0.5) }} />
            <AuthorBlock
              name={editor}
              summary={editor_summary}
              avatar={editor_avatar}
              action="Edited"
            />
          </>
        )}
        <div style={{ marginBottom: rhythm(0.5) }} />
        <div>
          <small>Let's talk about everything Cantonese on Discord</small>
          <br></br>
          <p>
            <small>
              You can find us by <a href="mailto:hulloanson@proton.me">email</a>{" "}
              or through{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://discord.gg/WA2WpvpA3f"
              >
                discord
              </a>
              .
            </small>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Bio
